




























import { Component, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

@Component({
  components: {
    Form_: () => import("@/app/modules/profile/components/PersonalInterestsForm.vue")
  }
})
export default class PersonalInterestsPage extends Vue {
  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView("Personal interests");
  }

  userTags: null | object = null;

  successMessage = false;

  get userData() {
    return this.$store.getters["profile/data"];
  }

  created() {
    this.userTags = this.$store.getters["tags/data"];
  }

  updateUserTags() {
    this.$store.dispatch("tags/update", this.userTags).then(() => {
      this.$store.commit("tags/setData", this.userTags);

      this.scrollTop();

      this.successMessage = true;
      setTimeout(() => (this.successMessage = false), 3000);
    });
  }

  scrollTop() {
    const card = document.getElementById("card");
    if (card) card.scrollIntoView({ behavior: "smooth" });
  }
}
